<template>
    <div>
        <section id="snippet-1" class="wrapper bg-light wrapper-border">
            <div class="container pt-15 pt-md-17 pb-13 pb-md-15">
                <div class="row mb-3">
                    <div class="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
                        <!-- <h2 class="fs-15 text-uppercase text-muted mb-3">Our Team</h2> -->
                        <h3 class="display-4 mb-7 px-lg-19 px-xl-18">Foto</h3>
                    </div>
                    <!--/column -->
                </div>
                <!--/.row -->
                <div class="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
                    <div class="col-md-6 col-lg-4" v-for="data in list_data" :key="data.id">
                        <div class="position-relative">
                            <div class="shape rounded bg-soft-blue rellax d-md-block" data-rellax-speed="0"
                                style="bottom: -0.75rem; right: -0.75rem; width: 98%; height: 98%; z-index:0"></div>
                            <br>
                            <div class="card">
                                <figure class="card-img-top">
                                    <a @click="selectData(data)" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#modal-signin">
                                        <img style="max-width: 100%; height: 350px !important;" :src="file_path + data.file" alt="" />
                                    </a>
                                </figure>
                                <div class="card-body px-6 py-5">
                                    <a @click="selectData(data)" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#modal-signin">
                                        <h4 class="mb-1">{{ data.judul }}</h4>
                                    </a>
                                    <!-- <p class="mb-0">{{ data.jabatan }}</p> -->
                                </div>
                            </div>
                            <div class="modal fade" id="modal-signin" tabindex="-1">
                                <div class="modal-dialog modal-lg modal-dialog-centered modal-sm">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            <img :src="file_path + form.file" alt="" style="width: 100%; height: auto !important;" />
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-sm btn-secondary"
                                                data-bs-dismiss="modal">Keluar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <br><br>
                <div class="text-center mt-50">
                    <button @click="btn_prev" type="button" class="btn btn-primary btn-sm rounded-pill" id="pref">
                        <i class="uil uil-arrow-left"></i>
                    </button>
                    &nbsp;
                    <span style="font-size: 85%; color: #a5a5a5">
                        <b>{{ page_first }} - {{ page_last }}</b>
                    </span>
                    &nbsp;
                    <button @click="btn_next" type="button" class="btn btn-primary btn-sm rounded-pill" id="next">
                        <i class="uil uil-arrow-right"></i>
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

const ADD_URL = URL_APP + "api/v1/publish_dpmptsp_foto/";
const FILE_LOAD = URL_APP + "uploads/";


export default {

    data() {
        return {
            data_load: false,
            form: {
                id: '',
                judul: '',
                deskripsi: '',
                file: '',
                unit_kerja: '',
                status: '',
                createBy: '',
                createAt: '',
            },
            id: '',
            cek_load_data: true,
            file_old: "",
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",
        }
    },
    methods: {
        getView: function () {
            // this.cek_load_data = true;
            fetch(ADD_URL + "view", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    data_ke: this.page_first,
                    cari_value: this.cari_value,
                    kategori: this.kategori,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    this.list_data = res_data.data;
                    this.page_last = res_data.jml_data;
                    this.cek_load_data = false;
                    // console.log(res_data);
                });
        },

        selectData: function (data) {
            // console.log(data);
            this.form = {
                id: data.id,
                judul: data.judul,
                deskripsi: data.deskripsi,
                file: data.file,
                unit_kerja: data.unit_kerja,
                status: data.status,
                createBy: data.createBy,
                createAt: data.createAt,
            };
        },
        ke_alamat: function (data) {
            window.open(data);
        },

        mdl_exit: function () {
            this.judul = "";
            this.uraian = "";
            this.file = "";
            this.file_old = "";
        },

        // ================== PAGINASI ====================
        alertku: function (type, title) {
            this.$swal({
                type: type,
                title: title,
                showConfirmButton: false,
                timer: 800
            });
        },

        btn_prev: function () {
            this.cek_load_data = true;
            if (this.page_first > 1) {
                this.page_first--
            } else {
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next: function () {

            if (this.page_first >= this.page_last) {
                this.page_first == this.page_last
            } else {
                this.page_first++;
            }
            this.getView();
        },

        cari_data: function () {

            this.page_first = 1;
            this.getView();
        },
        onFileSelected: function (event) {
            this.form.file = event.target.files[0];
        },
        convert_tgl: function (dateString) {
            var date = new Date(dateString);
            return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
        },
        // ================== PAGINASI ====================

        // ================== MODAL ====================
        buka_modal: function (modalku) {
            var modal = document.getElementById(modalku);
            modal.style.display = "block";
        },

        tutup_modal: function (modalku) {
            var modal = document.getElementById(modalku);
            modal.style.display = "none";
        },

        // ================== MODAL ====================
    },
    // =============================================================== METHODS =========================================================================

    mounted() {

        this.getView();

    }
};
</script>
